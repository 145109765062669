import React from "react";
import "./App.scss";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Provider, teamsTheme, ThemePrepared } from "@fluentui/react-northstar";
import { app as microsoftTeams } from "@microsoft/teams-js";
import RouteList from "./routes";
import MSTeamsWrapper from "./MSTeamsWrapper";
import { setMsTeamsTheme } from "./redux/slice/appSlice";
import { useDispatch } from "react-redux";
// import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useIsAuthenticated } from "@azure/msal-react";
// import { RootState } from "./redux/store";
// import { useSelector } from "react-redux";
// import { InteractionStatus } from "@azure/msal-browser";
// import { loginRequest } from "./config/AuthConfig";
// import { MyStorage } from "./storage/myStorage";
// import { userInfoRequestStart } from "./redux/slice/userInfoByAzureADSlice";
// import { userImgRequestStart } from "./redux/slice/userImgByAzureADSlice";


toast.configure({
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  newestOnTop: false,
  closeOnClick: true,
  rtl: false,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  theme: "colored",
});

export interface ITeamState {
  isOnTeams: boolean | null;
  activeTheme: { type: string; theme: ThemePrepared; };
  employeeId?: string | null;
  userId?: string | null;
  compEntity: string;
  azureAdToken?: string;
}


function App() {
  const dispatch = useDispatch();
  // const [userInfo, setUserInfo] = React.useState<{ token?: string, email?: string, firstName?: string, lastName?: string }>();

  // const { userInfoData, userInfoIsSuccess } = useSelector((state: RootState) => state.userInfoFromAD);


  const [teamState, setTeamState] = React.useState<ITeamState>({
    isOnTeams: null,
    activeTheme: { type: "default", theme: teamsTheme },
    employeeId: "",
    userId: "",
    compEntity: ""
  });

  const checkInTeams = (): boolean => {
    // eslint-disable-next-line dot-notation
    const microsoftTeamsLib = microsoftTeams || (window as any)["microsoftTeams"];

    if (!microsoftTeamsLib) {
      return false; // the Microsoft Teams library is for some reason not loaded
    }

    if (
      (window.parent === window.self && (window as any).nativeInterface) ||
      window.name === "embedded-page-container" ||
      window.name === "extension-tab-frame"
    ) {
      return true;
    }
    return false;
  };

  React.useEffect(() => {
    const teams = checkInTeams();
    setTeamState((prev) => ({ ...prev, isOnTeams: teams }));
  }, []);

  const getQueryVariable = (variable: string): string | null => {
    const query = window && window.location.search.substring(1);
    const vars = query.split("&");
    for (const varPairs of vars) {
      const pair = varPairs.split("=");
      if (decodeURIComponent(pair[0]) === variable) {
        return decodeURIComponent(pair[1]);
      }
    }
    return null;
  };

  React.useEffect(() => {
    // if (teamState.isOnTeams === false) {
    const empId = getQueryVariable("employeeId");
    const userId = getQueryVariable("userid");
    const compEntity = getQueryVariable("compEntity") || "";
    if (empId !== null && userId !== null && teamState.employeeId === "" && teamState.userId === "") {
      setTeamState((prev) => ({ ...prev, employeeId: empId, userId: userId, compEntity: compEntity as string }));
    }
    // }

  }, [teamState]);

  React.useEffect(() => {
    // Add or remove the 'darkMode' class on the body element based on activeTheme
    if (teamState.activeTheme.type === 'dark') {
      document.body.classList.add('darkMode');
    } else {
      document.body.classList.remove('darkMode');
    }

    // Cleanup function to ensure the class is removed if the component unmounts
    return () => {
      document.body.classList.remove('darkMode');
    };
  }, [teamState.activeTheme.type]);

  console.log("teamState", teamState);

  React.useEffect(() => {
    dispatch(setMsTeamsTheme(teamState.activeTheme.type));
  }, [teamState.activeTheme]);
  return (

    <Provider theme={teamState.activeTheme.theme} lang="en-US">
      <MSTeamsWrapper teamState={teamState} setTeamState={setTeamState}>
        <RouteList />
      </MSTeamsWrapper>
      {/* {teamState.isOnTeams === null ? (
        <LoaderComp />
      ) : teamState.isOnTeams ? (
        <MSTeamsWrapper teamState={teamState} setTeamState={setTeamState}>
          <RouteList />
        </MSTeamsWrapper>

      ) : (
        <RouteList />
      )} */}
    </Provider>
  );
}

export default App;

import { put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { httpPost } from "../../../services";
import { IMoodFeedbackResponse, ISaveMoodFeedbackPayload, moodFeedbackRequestFailure, moodFeedbackRequestStart, moodFeedbackRequestSuccess } from "../../slice/saveMoodFeedbackSlice";


function* saveMoodFeedbackSaga(action: PayloadAction<ISaveMoodFeedbackPayload>): Generator<any, void, IMoodFeedbackResponse> {
    const { userId, feedbackJson, week, day } = action.payload;
    try {

        if (!userId || !feedbackJson || !week || !day) {
            throw new Error("Missing parameters for saving Mood Feedback");
        }
        const url = `${process.env.REACT_APP_CANDID_API_URL}/api/v1/user/feedback`;
        const response = yield httpPost(url, { userId, feedbackJson, week, day, mode: 1 });
        response.day = day;
        response.week = week;
        console.log("res", response);
        yield put(moodFeedbackRequestSuccess(response));

    } catch (error: any) {
        const response = { day, week, error: error.message };
        yield put(moodFeedbackRequestFailure(response));
    }
}



export default function* saveFeedbacksaga() {
    yield takeLatest(moodFeedbackRequestStart.type, saveMoodFeedbackSaga);
}

import { combineReducers } from "@reduxjs/toolkit";
import appReducer from "./appSlice";
import dialogReducer from "./dialogSlice";
import userInfoFromADReducer from "./userInfoByAzureADSlice";
import getTokenExchangeReducer from "./getTokenExchange";
import userImgFromADReducer from "./userImgByAzureADSlice";
import saveMoodFeedbackReducer from "./saveMoodFeedbackSlice";
import updateUserTaskReducer from "./updateUserTask";
import getTimelineCardsReducer from "./getTimelineCards";
import userAccessDetailsReducer from "./userAccessDetails";


const rootReducer = combineReducers({
    app: appReducer,
    activeDialog: dialogReducer,
    tokenExchange: getTokenExchangeReducer,
    userInfoFromAD: userInfoFromADReducer,
    userImgFromAD: userImgFromADReducer,
    saveMoodFeedback: saveMoodFeedbackReducer,
    getTimelineCards: getTimelineCardsReducer,
    userAccessDetails: userAccessDetailsReducer,
    updateUserTask: updateUserTaskReducer

});

export default rootReducer;
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IUpdateCardPayload {
    uuId: string;
    id: string;
    feedback: string;
    day: number | null;
    cardID: number | null;
}

export const initialUpdateCardPayload = {
    uuId: "",
    id: "",
    feedback: "",
    day: null,
    cardID: null
};
export interface IAppConfig {
    dimension: { deviceWidth: number; deviceHeight: number; };
    path: number;
    msTeamsToken: string | null;
    appTheme: string;
    updateCardPayload: IUpdateCardPayload;

}

// Initial state matching the shape of IAppConfig
const initialState: IAppConfig = {
    dimension: { deviceWidth: 0, deviceHeight: 0 },
    path: 0,
    msTeamsToken: null,
    appTheme: "",
    updateCardPayload: initialUpdateCardPayload
};

const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        deviceDimensions(state, action: PayloadAction<{ deviceWidth: number; deviceHeight: number; }>) {
            state.dimension = action.payload;
        },
        setPath(state, action: PayloadAction<number>) {
            state.path = action.payload;
        },
        setMsTeamsToken(state, action: PayloadAction<string>) {
            state.msTeamsToken = action.payload;
        },
        setMsTeamsTheme(state, action: PayloadAction<string>) {
            state.appTheme = action.payload;
        },
        setUpdateCardPayload(state, action: PayloadAction<IUpdateCardPayload>) {
            state.updateCardPayload = action.payload;
        },

    },
});

// Export the actions
export const { deviceDimensions, setPath, setMsTeamsToken, setMsTeamsTheme, setUpdateCardPayload } = appSlice.actions;

// Export the reducer
export default appSlice.reducer;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";



interface IUserImgFromADState {
    userImgLoading: boolean;
    userImgIsSuccess: boolean;
    userImgIsError: boolean;
    userImgdata: string | null;
    userImgError: string | null;
}

const initialState: IUserImgFromADState = {
    userImgLoading: false,
    userImgIsSuccess: false,
    userImgIsError: false,
    userImgdata: null,
    userImgError: null,
};

const userImgApiSlice = createSlice({
    name: "getUserImgFromAdSlice",
    initialState,
    reducers: {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
        userImgRequestStart(state, action: PayloadAction<{ token: string }>) {
            state.userImgLoading = true;
            state.userImgIsSuccess = false;
            state.userImgIsError = false;
            state.userImgError = null;
        },
        userImgRequestSuccess(state, action: PayloadAction<string>) {
            state.userImgLoading = false;
            state.userImgIsSuccess = true;
            state.userImgIsError = false;
            state.userImgdata = action.payload;
        },
        userImgRequestFailure(state, action: PayloadAction<string>) {
            state.userImgLoading = false;
            state.userImgIsSuccess = false;
            state.userImgIsError = true;
            state.userImgError = action.payload;
        },
    },
});


export const { userImgRequestStart, userImgRequestSuccess, userImgRequestFailure } = userImgApiSlice.actions;
export default userImgApiSlice.reducer;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IAppConfig {
    dimension: { deviceWidth: number; deviceHeight: number; };
    path?: string;
    msTeamsToken: string | null;
    appTheme: string;

}

// Initial state matching the shape of IAppConfig
const initialState: IAppConfig = {
    dimension: { deviceWidth: 0, deviceHeight: 0 },
    path: "",
    msTeamsToken: null,
    appTheme: ""
};

const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        deviceDimensions(state, action: PayloadAction<{ deviceWidth: number; deviceHeight: number; }>) {
            state.dimension = action.payload;
        },
        setPath(state, action: PayloadAction<string>) {
            state.path = action.payload;
        },
        setMsTeamsToken(state, action: PayloadAction<string>) {
            state.msTeamsToken = action.payload;
        },
        setMsTeamsTheme(state, action: PayloadAction<string>) {
            state.appTheme = action.payload;
        },

    },
});

// Export the actions
export const { deviceDimensions, setPath, setMsTeamsToken, setMsTeamsTheme } = appSlice.actions;

// Export the reducer
export default appSlice.reducer;

import { put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";

import { httpGetForAD } from "../../../services";
import { Buffer } from "buffer";
import { IGetUserInfoFromAdPayload, IUserInfoResponse, userInfoRequestFailure, userInfoRequestStart, userInfoRequestSuccess } from "../../slice/userInfoByAzureADSlice";
import { userImgRequestFailure, userImgRequestStart, userImgRequestSuccess } from "../../slice/userImgByAzureADSlice";


function* fetchUserInfoSaga(action: PayloadAction<IGetUserInfoFromAdPayload>): Generator<any, void, IUserInfoResponse> {
    try {
        const { access_token, upn } = action.payload;

        if (!access_token || !upn) {
            throw new Error("upn or access_token is missing");
        }
        const config = {
            headers: {
                Authorization: `Bearer ${access_token}`,
                "Content-Type": "application/json",
            },
        };
        const response: IUserInfoResponse = yield httpGetForAD(`https://graph.microsoft.com/v1.0/users/${upn}?$select=employeeId,mailNickname,mail,userPrincipalName,displayName,givenName,surname`, config);
        yield put(userInfoRequestSuccess(response));
    } catch (error: any) {
        yield put(userInfoRequestFailure(error.message));
    }
}

function* fetchUserImageSaga(action: PayloadAction<{ token: string; }>): Generator<any> {
    try {
        const { token } = action.payload;

        if (!token) {
            throw new Error("Email or SSO token is missing");
        }
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "image/jpeg"
            },
            responseType: "arraybuffer"
        };
        const res: any = yield httpGetForAD("https://graph.microsoft.com/v1.0/me/photo/$value", config);
        const ImgBase64 = Buffer.from(res, "binary").toString("base64");
        const base64URL = `data:image/jpeg;base64,${ImgBase64}`;
        yield put(userImgRequestSuccess(base64URL));

    } catch (error: any) {
        yield put(userImgRequestFailure(error.message));
    }
}


export default function* userInfoSagaFromAD() {
    yield takeLatest(userInfoRequestStart.type, fetchUserInfoSaga);
    yield takeLatest(userImgRequestStart.type, fetchUserImageSaga);
}

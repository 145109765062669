import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IUpdateUserTaskResponse {
    success: boolean;
    message: string;
    data: any;
}

interface IUpdateUserTaskState {
    updateUserTaskLoading: boolean;
    updateUserTaskIsSuccess: boolean;
    updateUserTaskIsError: boolean;
    updateUserTaskData: IUpdateUserTaskResponse | null;
    updateUserTaskError: string | null;
}

const initialState: IUpdateUserTaskState = {
    updateUserTaskLoading: false,
    updateUserTaskIsSuccess: false,
    updateUserTaskIsError: false,
    updateUserTaskData: null,
    updateUserTaskError: null,
};

export interface IUpdateUserTaskPayload {
    uuId: string;
    id: string;
    feedback: string;
    day?: number;
    cardID?: number;
    feedbackType?: string;
}

const updateUserTaskSlice = createSlice({
    name: "updateUserTaskSlice",
    initialState,
    reducers: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        updateUserTaskRequestStart(state, action: PayloadAction<IUpdateUserTaskPayload>) {
            state.updateUserTaskLoading = true;
            state.updateUserTaskIsSuccess = false;
            state.updateUserTaskIsError = false;
            state.updateUserTaskError = null;
        },
        updateUserTaskRequestSuccess(state, action: PayloadAction<IUpdateUserTaskResponse>) {
            state.updateUserTaskLoading = false;
            state.updateUserTaskIsSuccess = true;
            state.updateUserTaskIsError = false;
            state.updateUserTaskData = action.payload;
        },
        updateUserTaskRequestFailure(state, action: PayloadAction<string>) {
            state.updateUserTaskLoading = false;
            state.updateUserTaskIsSuccess = false;
            state.updateUserTaskIsError = true;
            state.updateUserTaskError = action.payload;
        },
    },
});


export const { updateUserTaskRequestStart, updateUserTaskRequestSuccess, updateUserTaskRequestFailure } = updateUserTaskSlice.actions;
export default updateUserTaskSlice.reducer;

import React from "react";
// import axios from "axios";
import { app as microsoftTeams, authentication } from "@microsoft/teams-js";
import { teamsTheme, teamsDarkTheme, teamsHighContrastTheme } from "@fluentui/react-northstar";
import { useDispatch } from "react-redux";
// import { ErrorToast } from "../ToastifyComponent";
import { ITeamState } from "../App";
import { httpPost } from "../services";
import { userInfoRequestStart } from "../redux/slice/userInfoByAzureADSlice";
import { userImgRequestStart } from "../redux/slice/userImgByAzureADSlice";
import {
    setMsTeamsToken,
    //  setPath 
} from "../redux/slice/appSlice";
import { ErrorToast } from "../components/ToastifyComponent";
import { setTokenExchange } from "../redux/slice/getTokenExchange";
import { timelineCardRequestStart } from "../redux/slice/getTimelineCards";
import { userAccessDetailsRequestStart } from "../redux/slice/userAccessDetails";
import { MyStorage } from "../storage/myStorage";


type Props = {
    teamState: ITeamState;
    setTeamState: React.Dispatch<React.SetStateAction<ITeamState>>;
    children: JSX.Element;
};


const MSTeamsWrapper = ({ teamState, setTeamState, children }: Props) => {
    microsoftTeams.initialize();
    console.log("teamState", teamState);
    const dispatch = useDispatch();
    const updateTheme = (themeStr?: string | null): void => {
        switch (themeStr) {
            case "dark":
                setTeamState((prev) => ({ ...prev, activeTheme: { type: "dark", theme: teamsDarkTheme } }));
                break;
            case "contrast":
                setTeamState((prev) => ({ ...prev, activeTheme: { type: "contrast", theme: teamsHighContrastTheme } }));
                break;
            case "default":
            default:
                setTeamState((prev) => ({ ...prev, activeTheme: { type: "default", theme: teamsTheme } }));
        }
    };

    microsoftTeams.registerOnThemeChangeHandler((theme: string) => {
        updateTheme(theme);
    });


    const getMsTeamsAccessToken = async (context: any) => {
        const ssoURL = process.env.REACT_APP_RESOURCE_URL as string ?? "";
        console.log("url", ssoURL, context);
        try {
            const token = await authentication.getAuthToken({
                resources: [ssoURL]
            });
            return token;
            // return "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6IjNQYUs0RWZ5Qk5RdTNDdGpZc2EzWW1oUTVFMCJ9.eyJhdWQiOiI5Y2U0M2UxNy1kNGRlLTQyMmQtYjA1My0zOWFhMDA3ZmU2MDkiLCJpc3MiOiJodHRwczovL2xvZ2luLm1pY3Jvc29mdG9ubGluZS5jb20vYTNmYjE4MGQtMjNjNC00NGM5LWEyNDEtYzc4MTA5MjAyYmQzL3YyLjAiLCJpYXQiOjE3MzEwNDk4MzksIm5iZiI6MTczMTA0OTgzOSwiZXhwIjoxNzMxMDU1MzU4LCJhaW8iOiJBWFFBaS84WUFBQUE4UGhhbDJuYzZ1SFJMeFM0SmJJZ2NjMFdWRVhQMjNsTm9MNnRmUEdNK2FJdmhONTRmdzdqa3FLN2tNVDhBd1NOQ1FsTlNRSzhURVJURmZhMlp3Mm12eURVdzZjOUY5TnpBL3dMTllJaGlqTzhzeDlCNTdCditabklMbVorbzRmMktsQVMrSGd0K3Zxbzh3ODNDM3FOUXc9PSIsImF6cCI6IjVlM2NlNmMwLTJiMWYtNDI4NS04ZDRiLTc1ZWU3ODc4NzM0NiIsImF6cGFjciI6IjAiLCJuYW1lIjoiU0hBTlRJIExBTCBbIENUUCwgRHJpc2h0aS5FbmdhZ2VYIF0iLCJvaWQiOiJlYjg0YjhlZS01MWRhLTQ1ZWYtYWZmZi1hMjA0YTJjMzcyNmMiLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJzbGFsLmNlbGViYWxAdGF0YW1vdG9ycy5jb20iLCJyaCI6IjEuQVQwQURSajdvOFFqeVVTaVFjZUJDU0FyMHhjLTVKemUxQzFDc0ZNNXFnQl81Z2s5QU0wOUFBLiIsInNjcCI6ImFjY2Vzc19hc191c2VyIiwic3ViIjoiZW5hZGQ1REE1dy1IaldibTVJQ0RYQVBMT1JPQmJ5YnNmdkZEOEVRNWhnSSIsInRpZCI6ImEzZmIxODBkLTIzYzQtNDRjOS1hMjQxLWM3ODEwOTIwMmJkMyIsInV0aSI6IjcwR3FIVXBZSDBPSVZlV2FMTlhfQUEiLCJ2ZXIiOiIyLjAifQ.ZkYjOdn4ILWTm6KgSX4ET5vU7HpUUDIebrU9MoH7EHReyhjuKwqVRP2crjuYE66kTMX1gBaMRa5qEgjA5zmZAqI3_iuYeWs0_bxqGybrR_aDzunXMMoWL9kTJBTZxCHTuK2Z_pXzWjjU9oJCd90ipvUOAsNBGtj6c7u9i-NO-Cou-IhASignTMI89UnLfu0Hf8JY3TU-EZGx_z5W9DtjIHMAJfELC4qqGwGE8pb8MC9N-XK4V5yCddoOVRFMJHxte2drevP-5J-2NWII9oCojxYLtlVgaSwS9iWlcBIUXwN-5OD8XuFIAGB1zzKMiHsHuRBw5GRXvSkYf_1INmsUew";
        } catch (error) {
            ErrorToast("Error during authentication to fetch ms teams token");
            throw new Error("Error during authentication to fetch ms teams token:" + error);
        }
    };

    const tokenExchange = async (token: any) => {
        return await httpPost(
            `${process.env.REACT_APP_DRISHTI_API_URL}/api/azure-token/exchange/azure-token-exchange`,
            { token: token }
        );
    };

    React.useEffect(() => {
        // const context = {
        //     "app": {
        //         "locale": "en-us",
        //         "sessionId": "1fdcf8b8-9eb4-4ed9-91a8-1c283ec7f901",
        //         "theme": "default",
        //         "iconPositionVertical": 280,
        //         "parentMessageId": "",
        //         "userClickTime": 1730878693672,
        //         "userFileOpenPreference": "inline",
        //         "host": {
        //             "name": "TeamsModern",
        //             "clientType": "web",
        //             "sessionId": "e62168fe-063b-4c43-8cbb-78d12c58250d",
        //             "ringId": "general"
        //         },
        //         "appLaunchId": "d0d4e56a-5412-4e06-8e2b-3d887f811b4e"
        //     },
        //     "page": {
        //         "id": "index",
        //         "frameContext": "content",
        //         "subPageId": "",
        //         "isFullScreen": false,
        //         "isMultiWindow": false,
        //         "isBackgroundLoad": false,
        //         "sourceOrigin": ""
        //     },
        //     "user": {
        //         "id": "7b0b8c77-f1c3-4059-aeb6-21616f535122",
        //         "displayName": "",
        //         "licenseType": "SmbNonVoice",
        //         "loginHint": "shanti.lal@celebaltech.com",
        //         "userPrincipalName": "shanti.lal@celebaltech.com",
        //         "tenant": {
        //             "id": "e4e34038-ea1f-4882-b6e8-ccd776459ca0",
        //             "teamsSku": "enterprise"
        //         }
        //     },
        //     "sharePointSite": {
        //         "teamSiteUrl": "",
        //         "teamSiteDomain": "celebaltech.sharepoint.com",
        //         "teamSitePath": "",
        //         "teamSiteId": ""
        //     },
        //     "dialogParameters": {}
        // };
        // getMsTeamsAccessToken(context).then(
        //     (token: string) => {
        //         // dispatch(setMsTeamsToken(token));
        //         // tokenExchange(token).then(
        //         //     (res: any) => {8
        //         dispatch(setTokenExchange(token));
        //         dispatch(userInfoRequestStart({ access_token: token, upn: context?.user?.userPrincipalName as string }));
        //         dispatch(userImgRequestStart({ token: token as string }));
        //         dispatch(userAccessDetailsRequestStart({employeeId:"00509862"}));
        //         dispatch(timelineCardRequestStart({employeeId:"00509862"}));


        //         //     }).catch((err: unknown) => {
        //         //     console.log("Error while fetching token which use to call graph api ", err);
        //         //      ErrorToast("Error while fetching token exchange");
        //         // });
        //     }
        // ).catch((err) => console.log("Error during authentication to fetch ms teams token:", err));
        microsoftTeams
            .getContext()
            .then((context: any) => {
                // setEmail(context?.user?.userPrincipalName);
                console.log(context, "context");
                // if (context.page.subPageId !== "") {
                //     dispatch(setPath(context.page.subPageId));
                // }
                updateTheme(context.app.theme);
                getMsTeamsAccessToken(context).then(
                    (token: string) => {
                        MyStorage.setItem("msTeamsToken", token);
                        dispatch(setMsTeamsToken(token));
                        tokenExchange(token).then(
                            (res: any) => {
                                dispatch(setTokenExchange(res.access_token));
                                dispatch(userInfoRequestStart({ access_token: res.access_token, upn: context?.user?.userPrincipalName as string }));
                                dispatch(userImgRequestStart({ token: res.access_token as string }));
                                dispatch(userAccessDetailsRequestStart({ employeeId: "00509862" }));
                                dispatch(timelineCardRequestStart({ employeeId: "00509862" }));


                            }).catch((err: unknown) => {
                                console.log("Error while fetching token which use to call graph api ", err);
                                ErrorToast("Error while fetching token exchange");
                            });
                    }
                ).catch((err) => console.log("Error during authentication to fetch ms teams token:", err));
            })
            .catch((err: unknown) => {
                console.log("Error in Context", err);
                ErrorToast("Error while fetching MS teams context");
            });
    }, []);

    return (
        <>{children}</>
    );
};

export default MSTeamsWrapper;
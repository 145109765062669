import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IMoodFeedbackResponse {
    success: boolean
    message: string;
    day: number;
    week: number;
}

interface IMoodFeedbackState {
    moodFeedbackLoading: boolean;
    moodFeedbackIsSuccess: boolean;
    moodFeedbackIsError: boolean;
    moodFeedbackData: IMoodFeedbackResponse;
    moodFeedbackError: string | null;
}

const initialState: IMoodFeedbackState = {
    moodFeedbackLoading: false,
    moodFeedbackIsSuccess: false,
    moodFeedbackIsError: false,
    moodFeedbackData: {
        success: false,
        message: "",
        day: 0,
        week: 0,
    },
    moodFeedbackError: null,
};

export interface ISaveMoodFeedbackPayload {
    userId: string,
    feedbackJson: string;
    week: number;
    day: number;
    mode: number;
}

const saveMoodFeedbackSlice = createSlice({
    name: "saveMoodFeedbackSlice",
    initialState,
    reducers: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        moodFeedbackRequestStart(state, action: PayloadAction<ISaveMoodFeedbackPayload>) {
            state.moodFeedbackLoading = true;
            state.moodFeedbackIsSuccess = false;
            state.moodFeedbackIsError = false;
            state.moodFeedbackError = null;
        },
        moodFeedbackRequestSuccess(state, action: PayloadAction<IMoodFeedbackResponse>) {
            state.moodFeedbackLoading = false;
            state.moodFeedbackIsSuccess = true;
            state.moodFeedbackIsError = false;
            state.moodFeedbackData = action.payload;
        },
        moodFeedbackRequestFailure(state, action: PayloadAction<{ day: number, week: number, error: string }>) {
            state.moodFeedbackLoading = false;
            state.moodFeedbackIsSuccess = false;
            state.moodFeedbackIsError = true;
            state.moodFeedbackError = action.payload.error;
            state.moodFeedbackData.day = action.payload.day;
            state.moodFeedbackData.week = action.payload.week;

        },
    },
});


export const { moodFeedbackRequestStart, moodFeedbackRequestSuccess, moodFeedbackRequestFailure } = saveMoodFeedbackSlice.actions;
export default saveMoodFeedbackSlice.reducer;

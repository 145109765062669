import React, { Suspense } from "react";
import {
    createBrowserRouter, RouterProvider
} from "react-router-dom";
// import LazyFallBack from "../components/LazyFallBack";
import LoaderComp from "../components/LoaderComp";
const Overview = React.lazy(() => import("../pages/Overview"));

const RouteList = () => {
    const router = createBrowserRouter(
        [
            {
                path: "/",
                element:
                    <Suspense fallback={<LoaderComp />}>
                        <Overview />
                    </Suspense>
            }
        ],
        { basename: "/candid" }
    );
    return <RouterProvider router={router} />;
};

export default RouteList;
import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import rootSaga from "../saga";
import rootReducer from "../slice";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import persistStore from "redux-persist/es/persistStore";


const sagaMiddleware = createSagaMiddleware();

const middleware = [sagaMiddleware];
const persistConfig = {
  key: "root",
  storage,
  blacklist: ["userInfoFromAD", "userImgFromAD", "tokenExchange", "saveMoodFeedback", "getTimelineCards", "userAccessDetails", "app", "updateUserTask"]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(middleware),
  devTools: process.env.NODE_ENV !== "production"

});

sagaMiddleware.run(rootSaga);
const persistor = persistStore(store);


export default store;
export { persistor };

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

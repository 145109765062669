import { createSlice } from "@reduxjs/toolkit";

interface ActiveDialogState {
    activeDialog: {
        id?: string;
        actionName?: string;
        actionType?: "button" | "url" | "model" | "drawer";
        url?: string;
        header?: string;
    };
}

const initialState: ActiveDialogState = {
    activeDialog: {},
};

const activeDialogSlice = createSlice({
    name: "activeDialog",
    initialState,
    reducers: {
        activeDialog: (state, action) => {
            state.activeDialog = action.payload;
        }
    }
});

export const { activeDialog } = activeDialogSlice.actions;
export default activeDialogSlice.reducer;
import { all } from "redux-saga/effects";
import userInfoSagaFromAD from "./AzureADSaga";
import timelineCardData from "./TimelineCardDataSaga";
import saveFeedbacksaga from "./SaveMoodFeedbackSaga";
import userAccessDetailsSaga from "./UserAccessDetailsSaga";
import saveUserTaskSaga from "./UpdateUserTaskSaga";

function* rootSaga() {
    yield all([
        userInfoSagaFromAD(),
        timelineCardData(),
        saveFeedbacksaga(),
        userAccessDetailsSaga(),
        saveUserTaskSaga()
    ]);
}

export default rootSaga;

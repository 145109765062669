import React from "react";
import { app as microsoftTeams, authentication } from "@microsoft/teams-js";
import { teamsTheme, teamsDarkTheme, teamsHighContrastTheme } from "@fluentui/react-northstar";
import { useDispatch } from "react-redux";
import { ITeamState } from "../App";
import { httpPost } from "../services";
import { userInfoRequestStart } from "../redux/slice/userInfoByAzureADSlice";
import { userImgRequestStart } from "../redux/slice/userImgByAzureADSlice";
import {
    setMsTeamsToken, setPath
} from "../redux/slice/appSlice";
import { ErrorToast } from "../components/ToastifyComponent";
import { setTokenExchange } from "../redux/slice/getTokenExchange";
import { MyStorage } from "../storage/myStorage";


type Props = {
    teamState: ITeamState;
    setTeamState: React.Dispatch<React.SetStateAction<ITeamState>>;
    children: JSX.Element;
};


const MSTeamsWrapper = ({ teamState, setTeamState, children }: Props) => {
    microsoftTeams.initialize();
    const dispatch = useDispatch();
    const updateTheme = (themeStr?: string | null): void => {
        switch (themeStr) {
            case "dark":
                setTeamState((prev) => ({ ...prev, activeTheme: { type: "dark", theme: teamsDarkTheme } }));
                break;
            case "contrast":
                setTeamState((prev) => ({ ...prev, activeTheme: { type: "contrast", theme: teamsHighContrastTheme } }));
                break;
            case "default":
            default:
                setTeamState((prev) => ({ ...prev, activeTheme: { type: "default", theme: teamsTheme } }));
        }
    };

    microsoftTeams.registerOnThemeChangeHandler((theme: string) => {
        updateTheme(theme);
    });


    const getMsTeamsAccessToken = async (context: any) => {
        const ssoURL = process.env.REACT_APP_RESOURCE_URL as string ?? "";
        console.log("url", ssoURL, context);
        try {
            const token = await authentication.getAuthToken({
                resources: [ssoURL]
            });
            return token;
        } catch (error) {
            setTeamState((prev) => ({ ...prev, isError: true }));
            ErrorToast("Error during authentication to fetch ms teams token");
            throw new Error("Error during authentication to fetch ms teams token:" + error);
        }
    };

    const tokenExchange = async (token: any) => {
        return await httpPost(
            `${process.env.REACT_APP_DRISHTI_API_URL}/api/azure-token/exchange/azure-token-exchange`,
            { token: token }
        );
    };

    React.useEffect(() => {
        microsoftTeams
            .getContext()
            .then((context: any) => {
                // setEmail(context?.user?.userPrincipalName);
                console.log(context, "context");
                if (context.page.subPageId !== "") {
                    dispatch(setPath(context.page.subPageId));
                }
                updateTheme(context.app.theme);
                getMsTeamsAccessToken(context).then(
                    (token: string) => {
                        console.log("getMsTeamsAccessToken", token);
                        MyStorage.setItem("msTeamsToken", token);
                        dispatch(setMsTeamsToken(token));
                        tokenExchange(token).then(
                            (res: any) => {
                                dispatch(setTokenExchange(res.access_token));
                                dispatch(userInfoRequestStart({ access_token: res.access_token, upn: context?.user?.userPrincipalName as string }));
                                dispatch(userImgRequestStart({ token: res.access_token as string }));
                            }).catch((err: unknown) => {
                                setTeamState((prev) => ({ ...prev, isError: true }));
                                console.log("Error while fetching token which use to call graph api ", err);
                                ErrorToast("Error while fetching token exchange");
                            });
                    }
                ).catch((err) => {
                    setTeamState((prev) => ({ ...prev, isError: true }));
                    console.log("Error during authentication to fetch ms teams token:", err);
                });
            })
            .catch((err: unknown) => {
                console.log("Error in Context", err);
                setTeamState((prev) => ({ ...prev, isError: true }));
                ErrorToast("Error while fetching MS teams context");
            });
    }, []);

    return (
        <>{children}</>
    );
};

export default MSTeamsWrapper;
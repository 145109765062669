import { createSlice } from "@reduxjs/toolkit";
import { Field } from "../../components/FeedbackForm/apiInterface";

interface ActiveDialogState {
    activeDialog: {
        id?: string;
        actionName?: string;
        actionType?: "button" | "url" | "model" | "drawer";
        feedbackJson?: Field[];
        url?: string;
        header?: string;
        modelJson?: any;
        size?:string;
    };
}

const initialState: ActiveDialogState = {
    activeDialog: {},
};

const activeDialogSlice = createSlice({
    name: "activeDialog",
    initialState,
    reducers: {
        activeDialog: (state, action) => {
            state.activeDialog = action.payload;
        }
    }
});

export const { activeDialog } = activeDialogSlice.actions;
export default activeDialogSlice.reducer;
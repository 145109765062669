import { Flex, Image, Text } from "@fluentui/react-northstar";
import React from "react";
import Logo from "../../assets/Coin control.gif";

const LoaderComp = () => {
    return (
        <Flex vAlign="center" hAlign="center" gap='gap.medium' column padding="padding.medium" styles={{ minHeight: "100vh", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <Image
                src={Logo}
                alt="loading"
                styles={{
                    width: "8rem", height: "8rem",
                }}
            />
            <Text size='large' weight='semibold'>Loading Please wait...</Text>
        </Flex >
    );
};

export default LoaderComp;
import { put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { httpPut } from "../../../services";
import { IUpdateUserTaskPayload, IUpdateUserTaskResponse, updateUserTaskRequestFailure, updateUserTaskRequestStart, updateUserTaskRequestSuccess } from "../../slice/updateUserTask";


function* updateUserTaskSaga(action: PayloadAction<IUpdateUserTaskPayload>): Generator<any, void, IUpdateUserTaskResponse> {
    try {
        const { uuId, id, feedback } = action.payload;
        console.log(uuId, id, feedback);
        if (!uuId || !id || !feedback) {
            throw new Error("Missing parameters for Updating User Task");
        }
        const url = `${process.env.REACT_APP_DRISHTI_API_URL}/api/v1/user/update-user-task`;
        const response = yield httpPut(url, { uuId, id, feedback });
        // console.log("res", response);
        yield put(updateUserTaskRequestSuccess(response.data));

    } catch (error: any) {
        yield put(updateUserTaskRequestFailure(error.message));
    }
}



export default function* saveUserTaskSaga() {
    yield takeLatest(updateUserTaskRequestStart.type, updateUserTaskSaga);
}

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ITimelineCardDataResponse {
    employee_id: string;
    tasks: ITimelineData[];
}
interface CardJson {
    type: "flipCard" | "basicCard" | "videoCard";
    heading?: string;
    article?: string;
    flipArticle?: string;
    flipAction?: CardAction[];
    img?: string;
    isDialog?: boolean;
    isFeedbackDrawer?: boolean;
    action?: CardAction[];
    previewImg?: string;
    videoSrc?: string;
    visitUsUrl?: string;
}

export interface CardAction {
    id?: string;
    actionName?: string;
    actionType?: "button" | "url" | "model" | "drawer";
    url?: string;
    header?: string;
}
export type ITimelineData = {
    week: number;
    day: number;
    date: string;
    bot_adaptive_cards: any;
    timeline_cards: ITouchPointCard[];
};

export type ITouchPointCard = {
    id: number;
    task_id: string;
    name: string;
    card_order: number;
    description: string;
    json: CardJson;
    feedback: string | null;

}

interface ITimelineCardDataState {
    timelineCardLoading: boolean;
    timelineCardIsSuccess: boolean;
    timelineCardIsError: boolean;
    timelineCardData: ITimelineCardDataResponse | null;
    timelineCardError: string | null;
}

const initialState: ITimelineCardDataState = {
    timelineCardLoading: false,
    timelineCardIsSuccess: false,
    timelineCardIsError: false,
    timelineCardData: null,
    timelineCardError: null,
};

export interface IGetTimelineCardDataPayload {
    employeeId: string;
}

const timelineCardDataSlice = createSlice({
    name: "getTimelineCardDataSlice",
    initialState,
    reducers: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        timelineCardRequestStart(state, action: PayloadAction<IGetTimelineCardDataPayload>) {
            state.timelineCardLoading = true;
            state.timelineCardIsSuccess = false;
            state.timelineCardIsError = false;
            state.timelineCardError = null;
        },
        timelineCardRequestSuccess(state, action: PayloadAction<ITimelineCardDataResponse>) {
            state.timelineCardLoading = false;
            state.timelineCardIsSuccess = true;
            state.timelineCardIsError = false;
            state.timelineCardData = action.payload;
        },
        timelineCardRequestFailure(state, action: PayloadAction<string>) {
            state.timelineCardLoading = false;
            state.timelineCardIsSuccess = false;
            state.timelineCardIsError = true;
            state.timelineCardError = action.payload;
        },
    },
});


export const { timelineCardRequestStart, timelineCardRequestSuccess, timelineCardRequestFailure } = timelineCardDataSlice.actions;
export default timelineCardDataSlice.reducer;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IUserAccessDetails {
    success: boolean;
    message: string;
    data: IUserAccessDetailsResponse[];
}
export interface IUserAccessDetailsResponse {
    ID: number;
    First_Name: string;
    Last_Name: string,
    Email: string,
    Is_Active: boolean,
    Is_Delete: boolean,
    Enroll_Date: string,
    Plan_Active: boolean,
    Department: string,
    Business_Unit: string,
    Joining_Date: string,
    Employee_ID: string,
    User_Name: string,
    Comp_Name: string;
}

interface IUserAccessDetailsState {
    userAccessDetailsLoading: boolean;
    userAccessDetailsIsSuccess: boolean;
    userAccessDetailsIsError: boolean;
    userAccessDetailsData: IUserAccessDetails | null;
    userAccessDetailsError: string | null;
}

const initialState: IUserAccessDetailsState = {
    userAccessDetailsLoading: false,
    userAccessDetailsIsSuccess: false,
    userAccessDetailsIsError: false,
    userAccessDetailsData: null,
    userAccessDetailsError: null,
};

export interface IGetUserAccessDetailsPayload {
    employeeId: string;
}

const userAccessDetailsApiSlice = createSlice({
    name: "getUserAccessDetailsSlice",
    initialState,
    reducers: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        userAccessDetailsRequestStart(state, action: PayloadAction<IGetUserAccessDetailsPayload>) {
            state.userAccessDetailsLoading = true;
            state.userAccessDetailsIsSuccess = false;
            state.userAccessDetailsIsError = false;
            state.userAccessDetailsError = null;
        },
        userAccessDetailsRequestSuccess(state, action: PayloadAction<IUserAccessDetails>) {
            state.userAccessDetailsLoading = false;
            state.userAccessDetailsIsSuccess = true;
            state.userAccessDetailsIsError = false;
            state.userAccessDetailsData = action.payload;
        },
        userAccessDetailsRequestFailure(state, action: PayloadAction<string>) {
            state.userAccessDetailsLoading = false;
            state.userAccessDetailsIsSuccess = false;
            state.userAccessDetailsIsError = true;
            state.userAccessDetailsError = action.payload;
        },
    },
});


export const { userAccessDetailsRequestStart, userAccessDetailsRequestSuccess, userAccessDetailsRequestFailure } = userAccessDetailsApiSlice.actions;
export default userAccessDetailsApiSlice.reducer;

import React, { Suspense } from "react";
import {
    createBrowserRouter, RouterProvider
} from "react-router-dom";
// import LazyFallBack from "../components/LazyFallBack";
import LoaderComp from "../components/LoaderComp";
import { ITeamState } from "../App";
const Overview = React.lazy(() => import("../pages/Overview"));

type IRouteList = {
    QueryData: ITeamState;
};
const RouteList = ({ QueryData }: IRouteList) => {
    const router = createBrowserRouter(
        [
            {
                path: "/",
                element:
                    <Suspense fallback={<LoaderComp />}>
                        <Overview QueryData={QueryData} />
                    </Suspense>
            }
        ],
        { basename: "/candid" }
    );
    return <RouterProvider router={router} />;
};

export default RouteList;
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ITokenExchange {
    token: string | null;
}

const initialState: ITokenExchange = {
    token: null
};

const exchangeTokenSlice = createSlice({
    name: "tokenExchange",
    initialState,
    reducers: {
        setTokenExchange(state, action: PayloadAction<string>) {
            state.token = action.payload;
        }
    },
});

// Export the actions
export const { setTokenExchange } = exchangeTokenSlice.actions;

// Export the reducer
export default exchangeTokenSlice.reducer;

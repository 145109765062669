const eatErrors = true;

export const MyStorage = {
  setItem: function (item: string, value: string): void {
    try {
      localStorage.setItem(item, value);
    } catch (e) {
      if (!eatErrors) throw e;
    }
  },
  getItem: function (item: string): string | null {
    let value: string | null = null;
    try {
      value = localStorage.getItem(item);
     
    } catch (e) {
      if (!eatErrors) throw e;
    } 
    return value;
  },
  clear: function (): void {
    try {
      localStorage.clear();
    } catch (e) {
      if (!eatErrors) throw e;
      // Handle error if needed
    }
  },
  removeItem: function (item: string): void {
    try {
      localStorage.removeItem(item);
    } catch (e) {
      if (!eatErrors) throw e;
      // Handle error if needed
    }
  },
};

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IUserInfoResponse {
    "@odata.context": string;
    displayName: string;
    employeeId: string;
    givenName: string;
    mail: string;
    mailNickname: string;
    surname: string;
    userPrincipalName: string;


}

interface IUserInfoState {
    userInfoLoading: boolean;
    userInfoIsSuccess: boolean;
    userInfoIsError: boolean;
    userInfoData: IUserInfoResponse | null;
    userInfoError: any;
}

const initialState: IUserInfoState = {
    userInfoLoading: false,
    userInfoIsSuccess: false,
    userInfoIsError: false,
    userInfoData: null,
    userInfoError: null,
};

export interface IGetUserInfoFromAdPayload {
    access_token: string,
    upn: string;
}

const userInfoApiSlice = createSlice({
    name: "getUserInfoFromAdSlice",
    initialState,
    reducers: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        userInfoRequestStart(state, action: PayloadAction<IGetUserInfoFromAdPayload>) {
            state.userInfoLoading = true;
            state.userInfoIsSuccess = false;
            state.userInfoIsError = false;
            state.userInfoError = null;
        },
        userInfoRequestSuccess(state, action: PayloadAction<IUserInfoResponse>) {
            state.userInfoLoading = false;
            state.userInfoIsSuccess = true;
            state.userInfoIsError = false;
            state.userInfoData = action.payload;
        },
        userInfoRequestFailure(state, action: PayloadAction<string>) {
            state.userInfoLoading = false;
            state.userInfoIsSuccess = false;
            state.userInfoIsError = true;
            state.userInfoError = action.payload;
        },
    },
});


export const { userInfoRequestStart, userInfoRequestSuccess, userInfoRequestFailure } = userInfoApiSlice.actions;
export default userInfoApiSlice.reducer;
